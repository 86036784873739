/* all styles */

@font-face {
    font-family: 'SofiaProRegular';
    src: url('SofiaProRegular.woff2') format('woff'); 
}

*{
    font-family: 'SofiaProRegular', sans-serif;
}
  
body{
    background: #151515;
    overflow: scroll!important;
}

.primary{
    color: #FCA54C;
}


/* index styles */

.logo img{
    height: auto;  
    max-width: 80px;
}
.nav{
    height: 150px!important; 
}

 
@media screen and (max-width: 760px){
    .nav{
        height: 50px!important;
    }
    .watch .notification img{
        width: 40px;
    }
    .watch .comment-flow img{
        width: 40px!important; 
        height: 40px!important; 
    }
}
@media screen and (min-width: 768px){
    .logo img{
        height: auto;  
        max-width: 160px;
    } 

    .navbar{
        height: 200px;
    } 
}

.navbar{
    position: absolute;
    width: 100%;
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.884) 49%, rgba(32, 27, 17, 0) 97%, rgba(48,40,25,0) 100%);
}

.navbar a{
    transition: .2s ease;
}

 

.links{
    transition: .2s;
}
.links:hover{
    color: #9844AC;
}

.button{
    background-color: #BF3B7D; 
    color: #fff; 
    transition: .3s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button:hover{
    background-color: #9844AC;
    color: #fff!important;
}

.hero{ 
    max-height: 100vh;
    overflow: hidden;
    position: relative;
}

.hero::before{
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0,0,0,0.9864320728291317) 0%, rgba(39,32,19,0.7903536414565826) 74%, rgba(48,40,25,0.700717787114846) 100%);
}

.hero img{
    width: 100%;   
 }

    /* .login.signup .hero{
        overflow-y: scroll;  
        height: 200vh!important;
    } */


 .hero-bg{
    width: 100%!important;
    height: 100% !important; 
    object-fit: cover;
 }

 ::-webkit-scrollbar{
    width: 4px;
 }

 ::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background-color: #BF3B7D;
 }

.hero-content{
    z-index: 3;
    width: 100%;
    position: absolute;
    top: 30%; 
    left: 10%;
}

.address input{
    border: 3px solid grey;
    background: transparent;
    color: #fff;
}

.address .button{
    margin-left: -5%; 
    /* border-radius: 10px;
    padding: 12px 100px; */
}

.games{
    background: rgb(41,26,13);
    background: linear-gradient(135deg, rgba(41,26,13,1) 0%, rgba(28,14,26,1) 38%, rgba(5,0,6,1) 91%);
}


.footer{ 
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.712) 49%, rgba(32, 27, 17, 0.116) 97%, rgba(48, 40, 25, 0) 100%);
}

.footer img{
    max-height: 200px;
    width: auto;
}


/* Login page */

.login .hero{
    min-height: 70vh;
    padding: 0;
}

.content{ 
    background: #151515; 
}
 

.login .hero-bg{
    width: auto;
    height: 100%!important;
}


.cont form input[type ='email'], .cont form input[type ='password'], .cont form input[type ='text'], .cont form input[type ='number']{
     background: transparent;
    border: 3px solid grey;
}

.login form input[type ='email'], .login form input[type ='password'], .login form input[type ='text'], .cont form input[type ='number']{
    display: block; 
    background: transparent;
    border: 3px solid grey;
}

/* home videos */

.home-videos .thumb-footer img{
    aspect-ratio: 1; 
    height: 50px; 
    min-width: 50px; 
    object-fit: cover; 
    border-radius: 50%; 
}

.home-videos .thumb .thumbnail{
    border: 2px solid #752647;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 2px 5px 20px #030303;
}

.home-videos .thumb .thumbnail img{ 
    width: 100%;
    height: 200px;
    object-fit: cover;
}

/* spaces */

.spaces .thumb .thumbnail {
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    height: 200px;
}
.spaces .thumb .thumbnail::before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: rgba(151, 68, 172, 0.308);
    background-blend-mode: multiply;
}

.spaces .thumb .thumb-footer img{
    background: #CD3981;
    border-radius: 30%;
    height: 40%; 
}

.spaces .thumb .thumb-footer .listeners{
    color: #CD3981;
    display: flex; 
}

.spaces .thumb .thumb-footer .listeners span{
    border-radius: 50%;
    height: 12px;
    aspect-ratio: 1;
    background-color: #752647;
    display: block;
    margin: auto 3px;
    
}

.spaces .thumb .thumb-footer .listeners span:first-child{
    background-color: #461E2E;
}
.spaces .thumb .thumb-footer .listeners span:last-child{
    background-color: #D7387A;
}

/* watchpage */
.react-player__preview {
    max-width: 820px!important;
}
 
.react-player__preview img{
    /* Example styles */
    max-width: 820px!important;
    height: 400px; /* Maintain aspect ratio */ 
    object-fit: cover; /* Cover the entire area, preserving aspect ratio */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow effect */
}

.watch {
    background-color: transparent;
    overflow-x: hidden;
}

.watch .reactplayer {
    width: auto!important;
    height: auto!important;
}

.watch .reactplayer video{
    width: 100%!important;
    aspect-ratio: 6/3!important; 
    border-radius: 20px;
}

.watch .recommend {
    background-color: #27282E;
    width: 400px;
}

.watch .thumb-footer img{
    width: 50px;
    height: 50px;
    aspect-ratio: 1;
    object-fit: cover; 
    border-radius: 50%; 
}

.watch .thumb .thumbnail img{
    border-radius: 10px; 
    max-height: 120px;
    width: 100%;
    object-fit: cover;
}

.watch .thumb .thumb-footer p{
    font-size: .8em;
    line-height: 1em;
}

.watch .notification img{
    max-width: 60px;
    max-width: 60px;
    aspect-ratio: 1;
    object-fit: cover; 
    border-radius: 50%; 
}

.watch .description {
    background-color: #2B2D34;
}

.watch .description span{
    color: #686869;
}

.watch .comments input{
    height: 70px;
    background-color: #2D2D2D;

}
.watch .comments button{
    cursor: pointer; 
    transition: .5s;
}

 .btn-dark{
    background-color:#707278;
    
}

 .btn-dark:hover{
    background-color: #686a6e;
}

.watch .comment-flow img{
    width: 50px;
    aspect-ratio: 1;
    object-fit: cover; 
    border-radius: 50%; 
}

.watch .comment-flow span{
    color: #686869;
}

/* shows */
.shows .thumbnail, .segments .thumbnail{
    height: 150px; 
    overflow: hidden;
    position: relative;
}

.shows .thumb img, .segments .thumb .thumbnail img{  
    width: 100%;
    height: 100%; 
    object-fit: cover;  
    position: absolute;
}

.shows .thumb img:hover{
    top: -6px;
}

/* segments */

.segments .seg-container{ 
    max-height: 90vh;
    overflow: hidden;
}


.segments .seg-container img{  
    z-index: -2;
    width: 100%; 
    object-fit: cover;
}
/* .segments .seg-container .thumb img{ 
    max-width: 15%; 
    aspect-ratio: 1; 
    position: absolute;
    z-index: 2; 
    border-radius: 50%; 
    top: 80%;
    left: 10%;    
} */

/* sidebar */
.side-bar{ 
    border-top: 3px solid #EEEEEE;
    width: 350px;
}
.side-bar ul li {
    padding: 10px 20px;
}

.about , .terms, .privacy{
    background-color: #343440;
}

.cont{
    background-color: #28262F;
}

/* audio player */
/* src/AudioPlayer.css */
.audio-player {   
    width: 100%; 
    height: 40vh;
  }

 
  .progress-bar {
    position: relative;

  }
  
  .progress-bar div {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
  }
  
  .img-container{
    height: 200px;
    width: 200px;
  }

  .img-container img{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .imgs-container{ 
    overflow: hidden;
  }

   /* internship */

   .oval { 
    background-color: #bf3b7dab;
    border-radius: 100% 100% 0% 0%;  
    overflow: hidden;
}

.oval img{
    height: 100%;
    width: 100%;
    right: 10%;
}

@media (max-width: 430px){
    .oval{ 
        margin-top: 100px;
    }
}


